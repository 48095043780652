<template>
  <div>
    <MaterialsOverview />
  </div>
</template>

<script>
import MaterialsOverview from '@/components/materialsNominvative/MaterailsOverview.vue'

export default {
  components: {
    MaterialsOverview,
  },
}

</script>
